import React, { useState } from "react";
import axios from "axios";
import Tooltip from '@mui/material/Tooltip'; // If using Material-UI for tooltips
import InfoIcon from '@mui/icons-material/Info'; // Importing an "i" icon

import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
} from "@mui/material";

function App() {
  const [email, setEmail] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSearch = async () => {
    if (!email) {
      alert("Please enter an email address.");
      return;
    }




    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + `api/transactions?email=${email}`
      );
      setData(response.data);
    } catch (err) {
      setError("Failed to fetch data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  function getStatusText(status, errorCode) {
    if (status === "succeeded") {
      return {
        status: "Succeeded",
        code: '',
        description: ''
      };
    } 
      return getDescriptionFromStatusCode(errorCode)
    }

    function getDescriptionFromStatusCode(errorCode) {

      // Define failed messages
    const failedMessages = [
      { 
        code: "invalid_cvc",
        message: "The card’s security code is invalid. Check the card’s security code or use a different card."
      }

    ];

    // Define incomplete messages
    const incompleteMessages = [
      { 
        code: "payment_intent_authentication_failure",
        message: "3D Secure attempt failed / Authentication Failure."
      }
    ];

    // Search for the code in failed messages
    const failed = failedMessages.find((item) => item.code  === errorCode);
    if (failed) {
      return {
        status: "Failed",
        code: failed.code,
        description: failed.message
      };
    }

    // Search for the code in incomplete messages
    const incomplete = incompleteMessages.find((item) => item.code === errorCode);
    if (incomplete) {
      return {
        status: "Incomplete",
        code: incomplete.code,
        description: incomplete.message
      };
    }

    // Default response if code is not found
    return {
      status: "Failed",
      code: errorCode,
      description: "Payment declined"
    };
  }

  const currencySymbols = {
    USD: '$',
    EUR: '€',
    GBP: '£',
    JPY: '¥',
    INR: '₹',
    AUD: 'A$',
    CAD: 'C$',
    // Add more currencies as needed
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: "20px" }}>
      {/* Form Section */}
      <Box
        style={{
          maxWidth: "900px",
          margin: "0 auto",
          padding: "20px",
          textAlign: "center",
        }}
      >
        <Typography variant="h4" gutterBottom>
        TradingProf Check App
        </Typography>
        <Box display="flex" gap="10px" marginBottom="20px" alignItems="center">
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{ flexGrow: 1 }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            disabled={loading}
            style={{ whiteSpace: "nowrap" }}
          >
            Search
          </Button>
        </Box>
        {loading && (
          <Box display="flex" justifyContent="center" margin="20px 0">
            <CircularProgress />
          </Box>
        )}
        {error && <Alert severity="error">{error}</Alert>}
      </Box>

      {/* Table Section */}
      {data.length > 0 && (
        <Box
          style={{
            marginTop: "20px",
            width: "100%",
            maxWidth: "1200px",
            margin: "0 auto",
          }}
        >
          <TableContainer component={Paper}>
            <Table
              style={{
                tableLayout: "fixed",
                width: "100%",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: "140px" }}>Date</TableCell>
                  <TableCell style={{ width: "80px" }}>Amount</TableCell>
                  <TableCell style={{ width: "120px" }}>Status</TableCell>
                  <TableCell
                    style={{
                      minWidth: "200px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    Description
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "200px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    Customer Email
                  </TableCell>
                  <TableCell
                    style={{
                      minWidth: "150px",
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                    }}
                  >
                    Payment Method
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{item.Date}</TableCell>
                    <TableCell>
                    {currencySymbols[item.Currency.toUpperCase()] || item.Currency.toUpperCase()}{item.Amount} 
                    </TableCell>
                    <TableCell>
  <span
    style={{
      padding: '4px 8px',
      borderRadius: '12px',
      fontSize: '0.875rem',
      fontWeight: 'bold',
      color: 'white',
      backgroundColor: getStatusText(item.Status, item.errorCode).status.toLowerCase() === 'succeeded'
        ? 'green'
        : getStatusText(item.Status, item.errorCode).status.toLowerCase() === 'failed'
        ? 'red'
        : 'gray',
    }}
  >
    {getStatusText(item.Status, item.errorCode).status}
  </span>
  {getStatusText(item.Status, item.errorCode).status.toLowerCase() !== 'succeeded' && (
    <Tooltip title={getStatusText(item.Status, item.errorCode).description} arrow>
      <InfoIcon style={{ fontSize: 16, color: 'gray', marginLeft: 8, cursor: 'pointer' }} />
    </Tooltip>
  )}
</TableCell>
                    <TableCell style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                      {item.Description}
                    </TableCell>
                    <TableCell style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                      {item.CustomerEmail}
                    </TableCell>
                    <TableCell style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                      {item["Payment Method"]}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Container>
  );
}

export default App;
